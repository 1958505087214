import mock from "./mock";
import axios from "axios";
import "./chat/ChatData";  
import "./todo/TodoData";
import "./notes/NotesData";
import "./contacts/ContactsData";
mock.onAny().passThrough();

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

export default axios;
